import minBy from 'lodash/minBy';
import some from 'lodash/some';

import { TEXT_OFFSET_X } from './constants';

const whitespaceSymbolRegex =
  // eslint-disable-next-line no-misleading-character-class
  /( |[\u4e00-\u9fcc]|\uD805\uDC4D|\uD836\uDE87|\u2014|[\u02BB\u060C\u2E32\u2E34\u2E41\u2E49\u3001\uFE10\uFE11\uFE50\uFE51\uFF0C\uFF64\u00B7\u055D\u07F8\u1363\u1802\u1808\uA4FE\uA60D\uA6F5\u02BD\u0312\u0313\u0314\u0315\u0326\u201A\uFF1B])/;
const URL_REGEX =
  /\[([^\]]+)\]\(((https?:\/\/|mailto:)[-A-Z0-9+&@#()/%?=~_|!:,.;]*[-A-Z0-9+()&@#/%=~_|])\)/gi;
const IMG_REGEX = /!\[([^\]])*\]\(((https?:\/\/)[-A-Z0-9+&@()#/*%?=~_|!:,.;]*[-A-Z0-9+&@#()/%=~_|])\)/gi;
const BOLD_REGEX = /\*\*(\S[\s\S]*?)\*\*/g;
const BOLD_ITALICS_REGEX = /\*\*\*(\S[\s\S]*?)\*\*\*/g;
const ITALICS_REGEX = /\*(\S[\s\S]*?)\*/g;

export type HighlightBlock = {
  startX: number;
  endX: number;
  text: string;
  lineNumber: number;
  style: {
    bold?: boolean;
    italic?: boolean;
  };
};

function scanForBlocks(
  canvasRef: HTMLCanvasElement,
  textBlocks: string[],
  maxWidth: number,
  font: string,
  customStyleFn: (block: HighlightBlock, index: number) => HighlightBlock['style'] = () => ({}),
): HighlightBlock[] {
  const ctx = canvasRef.getContext('2d');
  if (!ctx) {
    throw new Error('scanBlocks: context not found');
  }
  ctx.font = font;
  const singleCharacterSize = ctx.measureText('M').width;

  // general function that gets lines from array of text, gets a function to augment the blocks
  // The function takes in arrays of strings. Usually you would split the highlight text on some symbol
  // like hyper highlight or bold
  // This will then go block by block, and word by word, measuring the text to fit onto the page and will return
  // blocks of text and their start and end positions, as well as any metadata you wish to append using the customFn
  // We use it to find blocks that are bolded, or hyper highlighted
  let currentStart = TEXT_OFFSET_X;
  let currentLineCount = 0;
  const highlightBlocks: HighlightBlock[] = [];
  // For each block, take each word and measure it to fit onto the image
  for (const [blockIndex, block] of textBlocks.entries()) {
    // Skip empty blocks
    if (block.length === 0) {
      continue;
    }
    // Break up all the words by spaces, then add the spaces back in so we can actually draw them
    const words = block.split(whitespaceSymbolRegex);

    // The above code will keep words that are joined by newlines together still
    // Next, we need to break them up
    // first, split each word in the array into an array of words split on the newline character
    // if there are no newlines, the result will just be an array of size 1 containing that word
    const parsedWordsArray = words.map((w) => w.replace(/\r/g, '').split(/\n/));
    const parsedWords = [];

    // afterwards, go through each created array, and flatten it back to a list of words, adding new line characters
    // when needed (i.e, if the array is bigger than size 1)
    for (const words of parsedWordsArray) {
      for (let i = 0; i < words.length; i += 1) {
        parsedWords.push(words[i]);
        if (i !== words.length - 1) {
          parsedWords.push('\r\n');
        }
      }
    }

    let currentLine = '';
    for (const [i, word] of parsedWords.entries()) {
      // try the new word, and see if it fits
      const newLine = i > 0 ? `${currentLine}${word}` : `${word}`;
      const { width } = ctx.measureText(newLine);
      // if its not too wide and the word is not a newline, add the word to the current line
      if (width - Number(singleCharacterSize) < maxWidth - currentStart && word !== '\r\n') {
        currentLine = newLine;
      } else {
        // Once the line is too long, add the block to our output, give the start, end, lineCount, and any extra metadata
        const endPos = ctx.measureText(currentLine).width + currentStart;
        if (currentLine.length > 0) {
          const textBlock: HighlightBlock = {
            startX: currentStart,
            endX: endPos,
            text: currentLine,
            lineNumber: currentLineCount,
            style: {},
          };
          highlightBlocks.push({
            ...textBlock,
            style: customStyleFn(textBlock, blockIndex),
          });
        }
        currentLine = word;
        currentLineCount += 1;
        currentStart = TEXT_OFFSET_X;
      }
    }
    // Once the loop is done, we probably still have one more line to add, add the remaining line to the output
    const endPos = ctx.measureText(currentLine).width + currentStart;
    if (currentLine.length > 0) {
      const textBlock: HighlightBlock = {
        startX: currentStart,
        endX: endPos,
        text: currentLine,
        lineNumber: currentLineCount,
        style: {},
      };
      highlightBlocks.push({
        ...textBlock,
        style: customStyleFn(textBlock, blockIndex),
      });
    }
    currentStart = endPos;
  }
  return highlightBlocks;
}

const scanForItalicBlocks = (
  canvas: HTMLCanvasElement,
  text: string,
  maxWidth: number,
  font: string,
) => {
  // remove everything but italic text
  const parsedText = text
    .replace(URL_REGEX, '$1')
    .replace(IMG_REGEX, '_readwise_img_')
    .replace(/(_readwise_img_\n)/g, '')
    .replace(BOLD_ITALICS_REGEX, '$1')
    .replace(BOLD_REGEX, '$1');
  const boldText = parsedText.split(ITALICS_REGEX);
  return scanForBlocks(canvas, boldText, maxWidth, font, (b, i) => ({
    // When the blocks get split on italic, every odd block must be italic
    // eg. "Mitch is _so_ smart" => ["mitch is", "so", "smart"] (the "so" must be italic)
    italic: i % 2 === 1,
  }));
};

const scanForBoldBlocks = (canvas: HTMLCanvasElement, text: string, maxWidth: number, font: string) => {
  // remove everything but bold text
  const parsedText = text
    .replace(URL_REGEX, '$1')
    .replace(IMG_REGEX, '_readwise_img_')
    .replace(/(_readwise_img_\n)/g, '')
    .replace(BOLD_ITALICS_REGEX, '$1');
  let boldText = parsedText.split(BOLD_REGEX);
  boldText = boldText.map((t) => t.replace(ITALICS_REGEX, '$1'));
  return scanForBlocks(canvas, boldText, maxWidth, font, (b, i) => ({
    bold: i % 2 === 1,
  }));
};

export const scanForTextBlocks = (
  canvas: HTMLCanvasElement,
  text: string,
  maxWidth: number,
  font: string,
) => {
  const parsedText = text
    .replace(URL_REGEX, '$1')
    .replace(IMG_REGEX, '_readwise_img_')
    .replace(/(_readwise_img_\n)/g, '')
    .replace(BOLD_ITALICS_REGEX, '$1')
    .replace(BOLD_REGEX, '$1')
    .replace(ITALICS_REGEX, '$1');
  return scanForBlocks(canvas, [parsedText], maxWidth, font);
};

const mergeBlocks = (blockArrays: HighlightBlock[][]): HighlightBlock[] => {
  const output: HighlightBlock[] = [];
  // Make copies of the blocks
  const blockCopies = blockArrays.map((b) => b.map((bi) => ({ ...bi })));
  while (blockCopies.length > 0 && some(blockCopies, (a) => a.length > 0)) {
    // find the current shortest block at the front of each array
    const minBlockCopy = minBy(
      blockCopies.filter((b) => b.length !== 0),
      (b) => b[0].endX - b[0].startX,
    );
    if (!minBlockCopy) {
      continue;
    }
    const currentSmallestBlock = { ...minBlockCopy[0] };
    // Find where this block overlaps with, and see if it needs to add properties
    // Scan through all other first blocks
    blockCopies.forEach((ba) => {
      const firstElem = ba[0];
      // if it overlaps (not sure how it cant), add its extra props
      if (firstElem) {
        currentSmallestBlock.style = { ...currentSmallestBlock.style, ...firstElem.style };
      }
    });
    output.push({ ...currentSmallestBlock });
    // shorten all blocks by the current Smallest Block
    blockCopies.forEach((ba) => {
      const firstElem = ba[0];
      // move start of each block to new end, and remove the text
      if (firstElem) {
        firstElem.startX = currentSmallestBlock.endX;
        firstElem.text = firstElem.text.slice(currentSmallestBlock.text.length);
        if (firstElem.text.length === 0) {
          // remove the block, we are done
          ba.splice(0, 1);
        }
      }
    });
  }
  return output;
};

export const scanAllHighlightTextBlocks = (
  canvas: HTMLCanvasElement,
  text: string,
  maxTextWidth: number,
  font: string,
) => {
  const boldBlocks = scanForBoldBlocks(canvas, text, maxTextWidth, font);
  const italicBlocks = scanForItalicBlocks(canvas, text, maxTextWidth, font);

  return mergeBlocks([boldBlocks, italicBlocks]);
};
