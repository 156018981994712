import React from 'react';

import Icon from './Icon';

export default function QuoteshotArrowRight({
  className = '',
  text = 'next',
}: { text?: string; className?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="18"
        height="34"
        viewBox="0 0 18 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L17 17L1 33"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}
