import {
  FreshVariantId,
  GradientVariantId,
  ModernVariantId,
  ScribbleVariantId,
} from '../types/quoteshots';

export const backgroundImageUrls = {
  [ScribbleVariantId.Brush]: '/quoteshot/scribble/brush.png',
  [ScribbleVariantId.Circles]: '/quoteshot/scribble/circles.png',
  [ScribbleVariantId.Arrows]: '/quoteshot/scribble/arrows.png',
  [ScribbleVariantId.Waves]: '/quoteshot/scribble/waves.png',

  [GradientVariantId.Pink]: '/quoteshot/gradient/pink.png',
  [GradientVariantId.Blue]: '/quoteshot/gradient/blue.png',
  [GradientVariantId.Yellow]: '/quoteshot/gradient/yellow.png',
  [GradientVariantId.Peach]: '/quoteshot/gradient/peach.png',
  [GradientVariantId.BlueDark]: '/quoteshot/gradient/blue-dark.png',
  [GradientVariantId.OrangeDark]: '/quoteshot/gradient/orange-dark.png',
  [GradientVariantId.PurpleDark]: '/quoteshot/gradient/purple-dark.png',
  [GradientVariantId.RedDark]: '/quoteshot/gradient/red-dark.png',
};

export const darkBackgroundImageUrls = {
  [ScribbleVariantId.Brush]: '/quoteshot/scribble/brush-dark.png',
  [ScribbleVariantId.Circles]: '/quoteshot/scribble/circles-dark.png',
  [ScribbleVariantId.Arrows]: '/quoteshot/scribble/arrows-dark.png',
  [ScribbleVariantId.Waves]: '/quoteshot/scribble/waves-dark.png',
};

export const previewImageUrls = {
  [ScribbleVariantId.Brush]: '/quoteshot/scribble/brush-preview.png',
  [ScribbleVariantId.Circles]: '/quoteshot/scribble/circles-preview.png',
  [ScribbleVariantId.Arrows]: '/quoteshot/scribble/arrows-preview.png',
  [ScribbleVariantId.Waves]: '/quoteshot/scribble/waves-preview.png',

  [GradientVariantId.Pink]: '/quoteshot/gradient/pink-preview.png',
  [GradientVariantId.Blue]: '/quoteshot/gradient/blue-preview.png',
  [GradientVariantId.Yellow]: '/quoteshot/gradient/yellow-preview.png',
  [GradientVariantId.Peach]: '/quoteshot/gradient/peach-preview.png',
  [GradientVariantId.BlueDark]: '/quoteshot/gradient/blue-dark-preview.png',
  [GradientVariantId.OrangeDark]: '/quoteshot/gradient/orange-dark-preview.png',
  [GradientVariantId.PurpleDark]: '/quoteshot/gradient/purple-dark-preview.png',
  [GradientVariantId.RedDark]: '/quoteshot/gradient/red-dark-preview.png',

  [ModernVariantId.OrangePink]: '/quoteshot/modern/orange-pink-preview.png',
  [ModernVariantId.BlueRed]: '/quoteshot/modern/blue-red-preview.png',
  [ModernVariantId.TealBlue]: '/quoteshot/modern/teal-blue-preview.png',
  [ModernVariantId.OrangePurple]: '/quoteshot/modern/orange-purple-preview.png',

  [FreshVariantId.BlueRed]: '/quoteshot/fresh/blue-red.png',
  [FreshVariantId.RedBlue]: '/quoteshot/fresh/red-blue.png',
  [FreshVariantId.GreyGreen]: '/quoteshot/fresh/grey-green.png',
  [FreshVariantId.GreenGrey]: '/quoteshot/fresh/green-grey.png',
  [FreshVariantId.BeigePurple]: '/quoteshot/fresh/beige-purple.png',
  [FreshVariantId.PurpleBeige]: '/quoteshot/fresh/purple-beige.png',
  [FreshVariantId.TealGreen]: '/quoteshot/fresh/teal-green.png',
  [FreshVariantId.GreenTeal]: '/quoteshot/fresh/green-teal.png',
};
export const darkPreviewImageUrls = {
  [ScribbleVariantId.Brush]: '/quoteshot/scribble/brush-dark-preview.png',
  [ScribbleVariantId.Circles]: '/quoteshot/scribble/circles-dark-preview.png',
  [ScribbleVariantId.Arrows]: '/quoteshot/scribble/arrows-dark-preview.png',
  [ScribbleVariantId.Waves]: '/quoteshot/scribble/waves-dark-preview.png',

  [ModernVariantId.OrangePink]: '/quoteshot/modern/orange-pink-dark-preview.png',
  [ModernVariantId.BlueRed]: '/quoteshot/modern/blue-red-dark-preview.png',
  [ModernVariantId.TealBlue]: '/quoteshot/modern/teal-blue-dark-preview.png',
  [ModernVariantId.OrangePurple]: '/quoteshot/modern/orange-purple-dark-preview.png',
};
