export const TOP_MARGIN = 24;
export const TITLE_TEXT_HEIGHT = 24;
export const TITLE_BOTTOM_MARGIN = 24;

export const BOTTOM_MARGIN = 24;
export const AUTHOR_TEXT_HEIGHT = 24;
export const AUTHOR_TOP_MARGIN = 24;
export const SECONDARY_FONT_SIZE = 16;
export const SECONDARY_FONT = `400 ${SECONDARY_FONT_SIZE}px Public Sans VF`;

export const TEXT_OFFSET_X = 48;

// Left-right inner padding for hyper highlights
export const HYPER_HIGHLIGHT_X_PADDING = 1;

// DPI for image quality, increasing this increases quality, and image size
export const PIXEL_RATIO = 4;

export const MAX_FONT_SIZE = 48;
